import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import {
  getCharsFromString,
  getNumbersFromString,
  isFeatureEnabled,
  isFeatureAvailable,
  getDisabledMessage,
  setDefaultOverageType,
  sendErrorReport,
  getPolicyHasMaxTransfers,
  parseMetadata,
} from 'shared/helpers';
import {
  Button,
  Checkbox,
  DateInput,
  DirtyFormAlert,
  InputErrorMessage,
  Label,
  SlidePane,
  Notice,
  Notification,
  NumberInput,
  TextArea,
  TextInput,
  RadioBtn,
  Selector,
} from 'shared/components';
import {
  errorMsg,
  platformFeatures,
  trialTypes,
  licenseTypes,
  overageTypes,
  validityTypes,
} from 'shared/constants';
import {
  checkCompanyConfigField,
} from 'shared/companyConfig';
import {
  validateRequiredNumber,
  debouncedValidateRequiredNumber,
  validateDate,
  debouncedValidateDate,
  validateFloatingUsers,
  debouncedValidateFloatingUsers,
  validateJSON,
  debouncedValidateJSON,
} from 'shared/validation';
import { patchLicense } from 'src/license/actions';
import './styles.scss';

const EditLicenseForm = ({
  closeCb,
  isOpen,
  license,
  refetchLicense,
  currentProduct,
}) => {
  // todo_metadata replace this check with company feature
  const companyDetails = useSelector(state => get(state, 'company.details'));
  const isEnterprisePlan = get(companyDetails, 'plan_type') === 'enterprise';
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const companyProducts = useSelector(state => get(state, 'products.list')); // todo use products list from state
  const productID = get(license, 'product.id');
  const licenseProduct = companyProducts.find(p => p.id === productID);
  const isUserBased = get(licenseProduct, 'authorization_method') === 'user';
  const totalActiveDevices = get(license, 'total_active_devices');
  const licenseID = get(license, 'id');
  const licenseType = get(license, 'license_type');
  const isSubscription = licenseType === 'subscription';
  const productFloatingTimeout = get(currentProduct, 'floating_timeout');
  const floatingInUse = get(license, 'floating_in_use_devices');

  const periodOptions = [
    { label: __('Years'), value: 'y' },
    { label: __('Months'), value: 'm' },
    { label: __('Days'), value: 'd' },
  ];
  const consumptionPeriodOptions = [
    { label: __('Daily'), value: 'daily' },
    { label: __('Weekly'), value: 'weekly' },
    { label: __('Monthly'), value: 'monthly' },
    { label: __('Annually'), value: 'annually' },
  ];
  const notAvailablePreventVmMessage = (
    <>
      {__('Virtual machine detection and management is not available in your plan.')}
      &nbsp;
      {__('Contact sales to support VM prevention.')}
    </>
  );
  const notAvailableFloatingMessage = (
    <>
      {__('Floating licenses are unavailable in your current plan.')}
      &nbsp;
      {__('Upgrade to use floating licenses.')}
    </>
  );
  const notAvailableMaintenanceMessage = (
    <>
      {__('Maintenance plan is unavailable in your current plan.')}
      &nbsp;
      {__('Upgrade to use maintenance plans.')}
    </>
  );

  const notAvailableMaxTransfersMessage = (
    <>
      {__('Device transfer limits are not available in your plan.')}
      &nbsp;
      {__('Contact sales to support device transfer limit.')}
    </>
  );

  const notAvailableHwKeyMessage = (
    <>
      {__('Hardware key licensing is not available in your current plan.')}
      &nbsp;
      {__('Contact sales to support hardware key licensing.')}
    </>
  );

  const getInitialSelectedDurationType = () => {
    const hasValidityPeriod = get(license, 'validity_period');
    if (hasValidityPeriod) { return validityTypes.period; }
    return validityTypes.duration;
  };

  const getInitialSelectedTrialType = () => {
    const hasValidityPeriod = get(license, 'validity_period');
    if (hasValidityPeriod) { return trialTypes.endDate; }
    return trialTypes.days;
  };

  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  // license type
  const [selectedDurationType, setSelectedDurationType] = useState(getInitialSelectedDurationType());
  const [licenseDurationValue, setLicenseDurationValue] = useState(getNumbersFromString(get(license, 'valid_duration') || '1y'));
  const [licenseDurationPeriod, setLicenseDurationPeriod] = useState(getCharsFromString(get(license, 'valid_duration') || '1y'));
  const [licenseDurationError, setLicenseDurationError] = useState('');
  const [validUntil, setValidUntil] = useState(get(license, 'validity_period') || '');
  const [validUntilError, setValidUntilError] = useState('');
  // grace period
  const [gracePeriod, setGracePeriod] = useState(get(license, 'grace_period') || 1);
  const [gracePeriodError, setGracePeriodError] = useState('');
  const [allowGracePeriod, setAllowGracePeriod] = useState(get(license, 'allow_grace_period') || false);
  // consumptions
  const [maxConsumptions, setMaxConsumptions] = useState(get(license, 'max_consumptions') || 1);
  const [maxConsumptionsError, setMaxConsumptionsError] = useState('');
  const [allowUnlimitedConsumptions, setAllowUnlimitedConsumptions] = useState(get(license, 'allow_unlimited_consumptions') || false);
  const [allowOverages, setAllowOverages] = useState(get(license, 'allow_overages') || false);
  const [maxOverage, setMaxOverage] = useState(get(license, 'max_overages') || 0);
  const [maxOverageError, setMaxOverageError] = useState('');
  const [selectedOverageType, setSelectedOverageType] = useState(setDefaultOverageType(license));
  const [resetConsumption, setResetConsumption] = useState(get(license, 'reset_consumption') || false);
  const [consumptionPeriod, setConsumptionPeriod] = useState(get(license, 'consumption_period') || 'monthly');
  const [consumptionPeriodError, setConsumptionPeriodError] = useState('');
  // trial
  const [isTrial, setTrial] = useState(get(license, 'is_trial') || false);
  const [selectedTrialType, setSelectedTrialType] = useState(getInitialSelectedTrialType());
  const [trialDays, setTrialDays] = useState(get(license, 'trial_days') || 1);
  const [trialDaysError, setTrialDaysError] = useState('');
  const [trialEndDate, setTrialEndDate] = useState(get(license, 'validity_period') || '');
  const [trialEndDateError, setTrialEndDateError] = useState('');
  const [preventVm, setPreventVm] = useState(get(license, 'prevent_vm') || false);
  const [preventMaxTransfers, setPreventMaxTransfers] = useState(get(license, 'max_transfers') === -1);
  const [hasMaxTransfers, setHasMaxTransfers] = useState(getPolicyHasMaxTransfers(license));
  const [maxTransfers, setMaxTransfers] = useState(get(license, 'max_transfers'));
  const [maxTransfersError, setMaxTransfersError] = useState('');
  // options
  const [maxActivations, setMaxActivations] = useState(get(license, 'max_activations') || 1);
  const [maxActivationsError, setMaxActivationsError] = useState('');
  const [allowUnlimitedActivations, setAllowUnlimitedActivations] = useState(get(license, 'allow_unlimited_activations') || false);
  const [maxLicenseUsers, setMaxLicenseUsers] = useState(get(license, 'max_license_users'));
  const [maxLicenseUsersError, setMaxLicenseUsersError] = useState('');
  const [unlimitedMaxLicenseUsers, setUnlimitedMaxLicenseUsers] = useState(get(license, 'max_license_users') === 0 && get(license, 'is_floating_cloud'));
  // floating
  const [isFloating, setIsFloating] = useState(get(license, 'is_floating') || false);
  const [isFloatingCloud, setIsFloatingCloud] = useState(get(license, 'is_floating_cloud') || false);
  const [floatingUsers, setFloatingUsers] = useState(get(license, 'floating_users') || 1);
  const [floatingUsersError, setFloatingUsersError] = useState('');
  const [floatingTimeout, setFloatingTimeout] = useState(get(license, 'floating_timeout') || productFloatingTimeout || 120);
  const [floatingTimeoutError, setFloatingTimeoutError] = useState('');
  // maintenance
  const [hasMaintenance, setHasMaintenance] = useState(get(license, 'enable_maintenance_period') || false);
  const [selectedMaintenanceDurationType, setSelectedMaintenanceDurationType] = useState(validityTypes.duration);
  const [hasMaintenanceValue, setHasMaintenanceValue] = useState(getNumbersFromString(get(license, 'maintenance_duration') || '1y'));
  const [hasMaintenancePeriod, setHasMaintenancePeriod] = useState(getCharsFromString(get(license, 'maintenance_duration') || '1y'));
  const [hasMaintenanceError, setHasMaintenanceError] = useState('');
  const [hasMaintenanceEndDate, setHasMaintenanceEndDate] = useState(get(license, 'maintenance_period') || '');
  const [hasMaintenanceEndDateError, setHasMaintenanceEndDateError] = useState('');
  // start_date
  const [startDate, setStartDate] = useState(get(license, 'start_date') || '');
  const [startDateError, setStartDateError] = useState('');
  // license borrowing
  const [canBorrow, setCanBorrow] = useState(get(license, 'can_borrow') || false);
  const [maxBorrowTime, setMaxBorrowTime] = useState(get(license, 'max_borrow_time') || 0);
  const [maxBorrowTimeError, setMaxBorrowTimeError] = useState('');
  // note
  const [licenseNote, setLicenseNote] = useState(get(license, 'note') || '');
  // metadata
  const [metadata, setMetadata] = useState(parseMetadata(get(license, 'metadata')));
  const [metadataError, setMetadataError] = useState('');
  // hardware_key
  const [isHwKeyAuth, setHwKeyAuth] = useState(get(license, 'is_hardware_key_auth'));

  const isLicenseActivated = Number(get(license, 'times_activated')) > 0;

  const getValidityPeriod = () => {
    const isPerpetual = licenseType === licenseTypes.perpetual;
    const isTrialEndDate = isTrial && selectedTrialType === trialTypes.endDate;
    const isTimeLimitedEndDate = licenseType === licenseTypes.time_limited && selectedDurationType === validityTypes.period;
    const isSubscriptionEndDate = licenseType === licenseTypes.subscription && selectedDurationType === validityTypes.period;

    if (!isTrial && isPerpetual) { return null; }
    if (isTrialEndDate) { return trialEndDate; }
    if (isTimeLimitedEndDate && !isTrial) {
      return validUntil;
    }
    if (isSubscriptionEndDate) { return validUntil; }
    return undefined;
  };

  const getValidDuration = () => {
    const isPerpetual = licenseType === licenseTypes.perpetual;
    const isConsumption = licenseType === licenseTypes.consumption;
    const isDurationValid = licenseDurationValue && licenseDurationPeriod;

    if (isTrial) {
      return undefined;
    }
    if (isPerpetual || isConsumption) {
      return null;
    }
    if (selectedDurationType === validityTypes.period) {
      return undefined;
    }
    if (isDurationValid) {
      return `${licenseDurationValue}${licenseDurationPeriod}`;
    }
    return undefined;
  };

  const getMaintenanceDuration = () => {
    const isDurationTypeSelected = selectedMaintenanceDurationType === validityTypes.duration;
    if (!hasMaintenance) {
      return undefined;
    }
    if (!isDurationTypeSelected) {
      return undefined;
    }
    if (hasMaintenanceValue && hasMaintenancePeriod) {
      return `${hasMaintenanceValue}${hasMaintenancePeriod}`;
    }
    return undefined;
  };

  const getMaintenancePeriod = () => {
    const isPeriodTypeSelected = selectedMaintenanceDurationType === validityTypes.period;
    if (!hasMaintenance) {
      return undefined;
    }
    if (!isPeriodTypeSelected) {
      return undefined;
    }
    if (hasMaintenanceEndDate) {
      return hasMaintenanceEndDate;
    }
    return undefined;
  };

  const getMaxOverage = () => {
    if (!allowOverages) {
      return undefined;
    }
    if (selectedOverageType === overageTypes.unlimited) {
      return 0;
    }
    if (selectedOverageType === overageTypes.custom) {
      return Number(maxOverage);
    }
    return undefined;
  };

  const getTrialDays = () => {
    if (!isTrial) {
      return undefined;
    }

    if (trialEndDate && selectedTrialType === trialTypes.endDate) {
      return undefined;
    }

    if (selectedTrialType === trialTypes.days) {
      return Number(trialDays);
    }
    return undefined;
  };

  const getFloatingUsers = () => {
    if (!isFloating && !isFloatingCloud) {
      return undefined;
    }
    if (floatingUsers) {
      return Number(floatingUsers);
    }
    return undefined;
  };

  const getFloatingTimeout = () => {
    if (!isFloating && !isFloatingCloud) {
      return undefined;
    }
    if (floatingTimeout) {
      return Number(floatingTimeout);
    }
    return undefined;
  };

  const getMaxTransfers = () => {
    if (preventMaxTransfers) {
      return -1;
    }
    if (hasMaxTransfers) {
      return Number(maxTransfers);
    }
    return 0;
  };

  const createLicenseData = () => {
    const validDuration = getValidDuration();
    const validityPeriod = getValidityPeriod();
    const maintenanceDuration = getMaintenanceDuration();
    const maintenancePeriod = getMaintenancePeriod();
    const maxOverages = getMaxOverage();
    const trialDaysNumber = getTrialDays();
    const floatingUsersNumber = getFloatingUsers();
    const floatingTimeoutValue = getFloatingTimeout();
    const maxTransfersValue = getMaxTransfers();

    const licenseData = {
      // duration
      valid_duration: isSubscription ? undefined : validDuration, // integration should handle this
      // grace period
      allow_grace_period: allowGracePeriod,
      grace_period: allowGracePeriod ? gracePeriod : undefined,
      // validity_period
      validity_period: isSubscription ? undefined : validityPeriod, // integration should handle this
      // validity_period: formatDate(validityPeriod, validityPeriodTimeFormat),
      // consumption
      max_consumptions: Number(maxConsumptions),
      allow_unlimited_consumptions: allowUnlimitedConsumptions,
      allow_overages: allowOverages,
      max_overages: maxOverages,
      reset_consumption: resetConsumption,
      consumption_period: resetConsumption ? consumptionPeriod : undefined,
      // trial
      is_trial: isTrial,
      trial_days: trialDaysNumber,
      // options
      max_activations: Number(maxActivations),
      allow_unlimited_activations: allowUnlimitedActivations,
      max_license_users: isUserBased ? Number(maxLicenseUsers) : undefined,
      prevent_vm: preventVm,
      max_transfers: maxTransfersValue,
      is_floating: isFloating,
      is_floating_cloud: isFloatingCloud,
      floating_users: floatingUsersNumber,
      floating_timeout: floatingTimeoutValue || productFloatingTimeout,
      // maintenance
      enable_maintenance_period: hasMaintenance,
      maintenance_duration: maintenanceDuration,
      maintenance_period: maintenancePeriod,
      start_date: startDate || null,
      can_borrow: canBorrow,
      max_borrow_time: maxBorrowTime,
      is_hardware_key_auth: isHwKeyAuth,
      note: licenseNote,
      metadata: metadata ? JSON.parse(metadata) : {},
    };

    return licenseData;
  };

  const handleLicenseDurationValueChange = async (val) => {
    setDirty(true);
    setLicenseDurationValue(val);
    const validationErr = await debouncedValidateRequiredNumber(val);
    setLicenseDurationError(validationErr);
  };

  const handleLicenseDurationPeriodChange = (val) => {
    setDirty(true);
    setLicenseDurationPeriod(val);
  };

  const handleAllowGracePeriod = (val) => {
    setDirty(true);
    setAllowGracePeriod(val);
    setGracePeriod(get(license, 'grace_period') || 1);
    setGracePeriodError('');
  };

  const handleGracePeriodChange = (val) => {
    setDirty(true);
    setGracePeriod(val);
    debouncedValidateRequiredNumber(val, true).then(err => setGracePeriodError(err));
  };

  const handleValidUntilChange = async (val, isOptional = false) => {
    setDirty(true);
    if (!val && isOptional) {
      setValidUntil(val);
      setValidUntilError('');
      return true;
    }

    setValidUntil(val);
    const validationErr = await validateDate(val);
    setValidUntilError(validationErr);
    return true;
  };

  const handleMaxActivationsChange = async (val) => {
    setDirty(true);
    setMaxActivations(val);
    const validationErr = await debouncedValidateRequiredNumber(val);
    setMaxActivationsError(validationErr);
    if (Number(val) >= Number(floatingUsers)) {
      setFloatingUsersError('');
    }
    if (
      !validationErr &&
      isUserBased &&
      (Number(val) < Number(maxLicenseUsers)) &&
      !allowUnlimitedActivations
    ) {
      setMaxActivationsError(errorMsg.maxActivations);
    }
    if (!validationErr && (Number(val) < Number(totalActiveDevices))) {
      if (!allowUnlimitedActivations) {
        setMaxActivationsError(errorMsg.maxActivationsDevices);
      }
    }
  };

  const handleAllowUnlimitedActivations = (val) => {
    setDirty(true);
    setAllowUnlimitedActivations(val);
    setMaxActivations(get(license, 'max_activations') || 1);
    setMaxActivationsError('');
    if (floatingUsers && !!val) {
      setFloatingUsersError('');
    }
  };

  const handleAllowUnlimitedConsumptions = (val) => {
    setDirty(true);
    setAllowUnlimitedConsumptions(val);
    setMaxConsumptions(get(license, 'max_consumptions') || 1);
    setMaxConsumptionsError('');
  };

  const handleSelectedTrialTypeChange = (val) => {
    setDirty(true);
    setSelectedTrialType(val);
    setTrialDays(get(license, 'trial_days') || 0);
    setTrialDaysError('');
    setTrialEndDate(get(license, 'validity_period') || '');
    setTrialEndDateError('');
  };

  const handleMaintenanceEndDateChange = async (val) => {
    setDirty(true);
    setHasMaintenanceEndDate(val);
    const validationErr = await debouncedValidateRequiredNumber(val);
    setHasMaintenanceEndDateError(validationErr);
  };

  const validateValue = async (val, cb, includeZero) => {
    setLoading(true);
    let errors;
    try {
      errors = await validateRequiredNumber(val, includeZero);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate edit license form value', { value: val });
    }
    setLoading(false);
    if (errors) {
      return false;
    }
    return true;
  };

  const validateMaxActivations = async () => {
    setLoading(true);
    let errors;

    try {
      errors = await validateRequiredNumber(maxActivations);
      setLoading(false);
      setMaxActivationsError(errors);

      if (
        !errors &&
        isUserBased &&
        (Number(maxActivations) < Number(maxLicenseUsers)) &&
        !allowUnlimitedActivations
      ) {
        setMaxActivationsError(errorMsg.maxActivations);
        errors = true;
      }
      if (!errors && (Number(maxActivations) < Number(totalActiveDevices))) {
        if (!allowUnlimitedActivations) {
          setMaxActivationsError(errorMsg.maxActivationsDevices);
          errors = true;
        }
      }
    } catch (err) {
      setLoading(false);
      sendErrorReport(err, 'Cannot validate max activations', { value: maxActivations });
    }

    if (errors) {
      return false;
    }
    return true;
  };

  const validateLicenseDuration = async () => {
    if (isTrial) {
      return true;
    }
    if (licenseType === licenseTypes.subscription) {
      return true;
    }
    if (licenseType === licenseTypes.time_limited && selectedDurationType === validityTypes.period) {
      return true;
    }
    const validationErr = await validateRequiredNumber(licenseDurationValue);
    setLicenseDurationError(validationErr);

    if (!licenseDurationPeriod) {
      setLicenseDurationError(__(errorMsg.required));
      return false;
    }
    if (validationErr) {
      return false;
    }
    return true;
  };

  const validateGracePeriod = async () => {
    if (!allowGracePeriod) {
      return true;
    }
    const validationErr = await validateRequiredNumber(gracePeriod);
    setGracePeriodError(validationErr);
    if (validationErr) {
      return false;
    }
    return true;
  };

  const validateExpirationDate = async (isOptional = false) => {
    if (isTrial) {
      return true;
    }
    if (licenseType === licenseTypes.subscription) {
      return true;
    }
    if (licenseType === licenseTypes.time_limited && selectedDurationType === validityTypes.duration) {
      return true;
    }
    if (!validUntil && isOptional) {
      setValidUntilError('');
      return true;
    }
    const validationErr = await validateDate(validUntil);
    setValidUntilError(validationErr);
    if (validationErr) {
      return false;
    }
    return true;
  };

  const validateConsumption = async () => {
    const isMaxConsumptionsValid = await validateValue(maxConsumptions, setMaxConsumptionsError);
    const isMaxOverageValid = await validateValue(maxOverage, setMaxOverageError, true);
    const isConsumptionPeriodValid = resetConsumption ? await validateValue(consumptionPeriod, setConsumptionPeriodError) : true;
    return isMaxConsumptionsValid && isMaxOverageValid && isConsumptionPeriodValid;
  };

  const validateLicenseTypeData = async () => {
    if (licenseType === licenseTypes.subscription) {
      const isDurationValid = await validateLicenseDuration();
      const isDateValid = await validateExpirationDate();
      const isGracePeriodValid = await validateGracePeriod();
      return isDurationValid && isDateValid && isGracePeriodValid;
    }
    if (licenseType === licenseTypes.time_limited) {
      const isDurationValid = await validateLicenseDuration();
      const isDateValid = await validateExpirationDate();
      return isDurationValid && isDateValid;
    }
    if (licenseType === licenseTypes.consumption) {
      const isValid = await validateConsumption();
      return isValid;
    }
    return true;
  };

  const validateLicenseTrial = async () => {
    if (!isTrial) {
      return true;
    }
    if (licenseType === licenseTypes.subscription) {
      return true;
    }
    if (selectedTrialType === trialTypes.days) {
      const isTrialDaysValid = await validateValue(trialDays, setTrialDaysError);
      return !!isTrialDaysValid;
    }

    if (selectedTrialType === trialTypes.endDate) {
      const validationErr = await validateDate(trialEndDate);
      setTrialEndDateError(validationErr);
      if (validationErr) {
        return false;
      }
      return true;
    }
    return false;
  };

  const validateMaintenanceDuration = async () => {
    if (selectedMaintenanceDurationType === validityTypes.period) {
      return true;
    }
    if (!hasMaintenancePeriod) {
      setHasMaintenanceEndDateError(__(errorMsg.required));
      return false;
    }
    const validationErr = await validateRequiredNumber(hasMaintenanceValue);
    setHasMaintenanceError(validationErr);
    if (validationErr) {
      return false;
    }
    return true;
  };

  const validateMaintenanceEndDate = async () => {
    if (selectedMaintenanceDurationType === validityTypes.duration) {
      return true;
    }
    const validationErr = await validateDate(hasMaintenanceEndDate);
    setHasMaintenanceEndDateError(validationErr);
    if (validationErr) {
      return false;
    }
    return true;
  };

  const validateMaintenance = async () => {
    if (!hasMaintenance) {
      return true;
    }
    const isDurationValid = await validateMaintenanceDuration();
    const isEndDateValid = await validateMaintenanceEndDate();
    return isDurationValid && isEndDateValid;
  };

  const validateFloating = async () => {
    if (!isFloating && !isFloatingCloud) {
      return true;
    }

    if (Number(floatingUsers) < floatingInUse) {
      setFloatingUsersError(errorMsg.maxSimultaneousUsersLessThanInUse);
      return false;
    }

    const validationErr = await validateFloatingUsers(floatingUsers, maxActivations, allowUnlimitedActivations, isFloatingCloud);
    setFloatingUsersError(validationErr);
    if (validationErr) {
      return false;
    }
    return true;
  };

  const validateStartDateField = async (val, cb, isOptional = false) => {
    if (!val && isOptional) {
      cb('');
      return true;
    }

    setLoading(true);
    let errors;

    try {
      errors = await validateDate(val);
      setLoading(false);
      cb(errors);
    } catch (err) {
      setLoading(false);
      sendErrorReport(err, 'Cannot validate license start date', { value: val });
    }

    if (errors) { return false; }
    return true;
  };

  const validateMetadata = async (val) => {
    setLoading(true);
    let errors;
    try {
      errors = await validateJSON(metadata);
      setMetadataError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate edit feature form value', { value: val });
    }
    setLoading(false);
    if (errors) { return false; }
    return true;
  };

  const handleCanBorrowChange = (val) => {
    setDirty(true);
    setCanBorrow(val);
    setMaxBorrowTime(get(license, 'max_borrow_time') || 0);
    setMaxBorrowTimeError('');
  };

  const handleMaxBorrowTimeChange = (val) => {
    setDirty(true);
    setMaxBorrowTime(val);
    validateValue(val, setMaxBorrowTimeError);
  };

  const validateBorrowing = async () => {
    if (!canBorrow) { return true; }
    const isMaxBorrowTimeValid = await validateValue(maxBorrowTime, setMaxBorrowTimeError);
    return isMaxBorrowTimeValid;
  };

  const isFormValid = async () => {
    const isLicenseTypeDataValid = await validateLicenseTypeData();
    const isMaxActivationsValid = await validateMaxActivations();
    const isTrialValid = await validateLicenseTrial();
    const isMaintenanceValid = await validateMaintenance();
    const isFloatingValid = await validateFloating();
    const isFloatingTimeoutValid = (isFloating || isFloatingCloud) ? await validateValue(floatingTimeout, setFloatingTimeoutError) : true;
    const isMaxLicenseUsersValid = isUserBased ? await validateValue(maxLicenseUsers, setMaxLicenseUsersError, isFloatingCloud) : true;
    const isMaxTransfersValid = hasMaxTransfers ? await validateValue(maxTransfers, setMaxTransfersError) : true;
    const isStartDateValid = await validateStartDateField(startDate, setStartDateError, true);
    const isBorrowingValid = await validateBorrowing();
    const isMetadataValid = await validateMetadata();

    return isLicenseTypeDataValid && isMaxActivationsValid
      && isTrialValid && isMaintenanceValid && isFloatingValid
      && isFloatingTimeoutValid && isMaxLicenseUsersValid && isMaxTransfersValid
      && isStartDateValid && isBorrowingValid && isMetadataValid;
  };

  const handleLicenseEdit = async (e) => {
    e.preventDefault();

    const isValid = await isFormValid();
    if (!isValid || loading) { return false; }
    if (!isDirty) {
      closeCb();
      return false;
    }

    setDirty(false);
    setLoading(true);
    const data = createLicenseData();

    try {
      await patchLicense(licenseID, data, companyID);
      Notification('success', __('Changes saved successfully'), __('License edited'));
      refetchLicense();
      closeCb();
    } catch (err) {
      sendErrorReport(err, 'Cannot edit license', data);
      setLoading(false);
      Notification('error', __('Your changes were not saved'), __('There was an error while saving your changes'));
    }
    return true;
  };

  const handleClose = () => {
    if (!isDirty) {
      closeCb();
    } else {
      setDirtyFormAlertDisplay(true);
    }
  };

  const getTitle = () => (
    <div className="EditLicenseFormTitle">
      <div className="title">{__('Edit license')}</div>
      <div className="confirm-btn">
        <Button
          theme="success"
          type="submit"
          onClick={handleLicenseEdit}
          disabled={loading}
        >
          {__('Confirm')}
        </Button>
      </div>
    </div>
  );

  return (
    <SlidePane
      closeCb={handleClose}
      isOpen={isOpen}
      title={getTitle()}
      width="65%"
      disabled={loading}
    >
      <form className="EditLicenseForm" onSubmit={handleLicenseEdit}>
        <div className="EditLicenseForm-main">
          <div className="sections-wrapper">
            <section>
              <div className="section-header first">
                {__('License type')}
              </div>
              <div className="license-type-settings section-settings">
                <div className="section-row">
                  <Label text={__('License type')} inputId="license-type" />
                  <TextInput
                    id="license-type"
                    value={licenseType}
                    handleChange={() => { }}
                    disabled
                  />
                </div>
                {licenseType === licenseTypes.subscription && (
                  <>
                    <div className="section-row">
                      <div className="form-row">
                        <Checkbox
                          label={__('Allow grace period')}
                          inputId="allow-grace-period"
                          checked={allowGracePeriod}
                          handleChange={handleAllowGracePeriod}
                        />
                      </div>
                      {allowGracePeriod && (
                        <div className="form-row">
                          <Label text={__('Grace period')} inputId="grace-period" description={__('Hours')} />
                          <NumberInput
                            handleChange={handleGracePeriodChange}
                            value={gracePeriod}
                            error={gracePeriodError}
                            min="1"
                            max="2147483647"
                          />
                        </div>
                      )}
                    </div>
                    <div className="section-row">
                      <Notice theme="warning" size="sm">
                        <div className="actionable-notice">
                          <div className="text">
                            {__('LicenseSpring requires an integration to an external source of truth (like a recurring billing system). LicenseSpring updates the status of the license according to the status of the subscription, which is handled by a 3rd party. Without this integration, subscription licenses remain valid until otherwise specified')}
                          </div>
                        </div>
                      </Notice>
                    </div>
                  </>
                )}
                {licenseType === licenseTypes.time_limited && !isTrial && (
                  <div className="section-row time-limited-options">
                    <div className="radio-btns">
                      <RadioBtn
                        name="time-limited-select"
                        label={__('Valid from activation:')}
                        inputId="valid_from"
                        description={__('Select the validity duration, which begins the moment the license has been activated')}
                        value={validityTypes.duration}
                        checked={selectedDurationType === validityTypes.duration}
                        handleChange={(val) => {
                          setDirty(true);
                          setSelectedDurationType(val);
                          setValidUntil(get(license, 'validity_period') || '');
                          setValidUntilError('');
                        }}
                      />
                      <div>
                        <div className="flex-row">
                          <NumberInput
                            handleChange={handleLicenseDurationValueChange}
                            value={licenseDurationValue}
                            min="0"
                            max="1000"
                            disabled={selectedDurationType === validityTypes.period}
                          />
                          <Selector
                            options={periodOptions}
                            value={licenseDurationPeriod}
                            handleChange={handleLicenseDurationPeriodChange}
                            disabled={selectedDurationType === validityTypes.period}
                          />
                        </div>
                        <InputErrorMessage text={licenseDurationError} />
                      </div>
                      <RadioBtn
                        name="time-limited-select"
                        inputId="valid_until"
                        label={__('Set end date')}
                        description={__('Set the date in the future at which the license is set to expire')}
                        value={validityTypes.period}
                        checked={selectedDurationType === validityTypes.period}
                        handleChange={(val) => {
                          setDirty(true);
                          setSelectedDurationType(val);
                          setLicenseDurationValue(getNumbersFromString(get(license, 'valid_duration') || '1y'));
                          setLicenseDurationPeriod(getCharsFromString(get(license, 'valid_duration') || '1y'));
                          setLicenseDurationError('');
                        }}
                      />
                      <DateInput
                        id="release"
                        handleChange={handleValidUntilChange}
                        value={validUntil}
                        error={validUntilError}
                        disabled={selectedDurationType === validityTypes.duration}
                      />
                    </div>
                  </div>
                )}
                {licenseType === licenseTypes.consumption && (
                  <>
                    <div className="section-row max-activations">
                      <Label text={__('Max consumptions')} inputId="max-consumption" />
                      <NumberInput
                        handleChange={(val) => {
                          setDirty(true);
                          setMaxConsumptions(val);
                          debouncedValidateRequiredNumber(val).then(err => setMaxConsumptionsError(err));
                        }}
                        value={maxConsumptions}
                        error={maxConsumptionsError}
                        min="1"
                        max="10000"
                        disabled={allowUnlimitedConsumptions}
                      />
                      <Checkbox
                        label={__('Allow unlimited consumptions')}
                        inputId="allow-unlimited-consumptions-checkbox"
                        checked={allowUnlimitedConsumptions}
                        handleChange={handleAllowUnlimitedConsumptions}
                      />
                    </div>
                    <div className="section-row">
                      <Checkbox
                        label={__('Allow overages')}
                        inputId="consumption-overages"
                        checked={allowOverages}
                        handleChange={(val) => {
                          setDirty(true);
                          setAllowOverages(val);
                          setMaxOverage(get(license, 'max_overages') || 0);
                          setMaxOverageError('');
                        }}
                        featureEnabled={isFeatureEnabled(platformFeatures.model_consumption)}
                        notEnabledMessage={getDisabledMessage()}
                      />
                    </div>
                    {allowOverages && (
                      <div className="section-row overage-options">
                        <div className="radio-btns radio-btns-overages">
                          <RadioBtn
                            name="overages-select"
                            inputId={overageTypes.unlimited}
                            label={__('Unlimited overages')}
                            value={overageTypes.unlimited}
                            checked={selectedOverageType === overageTypes.unlimited}
                            handleChange={(val) => {
                              setDirty(true);
                              setSelectedOverageType(val);
                              setMaxOverage(0);
                              setMaxOverageError('');
                            }}
                          />
                          <RadioBtn
                            name="overages-select"
                            inputId={overageTypes.custom}
                            label={__('Max overages')}
                            value={overageTypes.custom}
                            checked={selectedOverageType === overageTypes.custom}
                            handleChange={(val) => {
                              setDirty(true);
                              setSelectedOverageType(val);
                              setMaxOverage(get(license, 'max_overages') || 0);
                              setMaxOverageError('');
                            }}
                          />
                          <NumberInput
                            handleChange={(val) => {
                              setDirty(true);
                              setMaxOverage(val);
                              debouncedValidateRequiredNumber(val, true).then(err => setMaxOverageError(err));
                            }}
                            value={maxOverage}
                            min="0"
                            max="2147483647"
                            disabled={selectedOverageType === overageTypes.unlimited}
                          />
                          <InputErrorMessage text={maxOverageError} />
                        </div>
                      </div>
                    )}
                    <div className="section-row">
                      <Checkbox
                        label={__('Reset consumption')}
                        inputId="consumption-reset"
                        checked={resetConsumption}
                        handleChange={(val) => {
                          setDirty(true);
                          setResetConsumption(val);
                          setConsumptionPeriod(get(license, 'consumption_period'));
                        }}
                        featureEnabled={isFeatureEnabled(platformFeatures.model_consumption)}
                        notEnabledMessage={getDisabledMessage()}
                      />
                    </div>
                    {resetConsumption && (
                      <div className="section-row consumption-period-select">
                        <Label text={__('Consumption period')} />
                        <Selector
                          options={consumptionPeriodOptions}
                          value={consumptionPeriod}
                          handleChange={(val) => {
                            setDirty(true);
                            setConsumptionPeriod(val);
                            setConsumptionPeriodError('');
                          }}
                        />
                        <InputErrorMessage text={consumptionPeriodError} />
                      </div>
                    )}
                  </>
                )}
                {checkCompanyConfigField(companyID, 'isTrial') && licenseType !== licenseTypes.subscription && (
                  <div className="section-row">
                    <Checkbox
                      label={__('Is trial')}
                      inputId="license-trial"
                      checked={isTrial}
                      handleChange={(val) => {
                        setDirty(true);
                        setTrial(val);
                        setTrialDays(get(license, 'trial_days') || 0);
                        setTrialDaysError('');
                        setTrialEndDate(get(license, 'validity_period') || '');
                        setTrialEndDateError('');
                        setSelectedDurationType(validityTypes.duration);
                        setLicenseDurationValue(getNumbersFromString(get(license, 'valid_duration') || '1y'));
                        setLicenseDurationPeriod(getCharsFromString(get(license, 'valid_duration') || '1y'));
                        setLicenseDurationError('');
                        setValidUntil(get(license, 'validity_period') || '');
                        setValidUntilError('');
                      }}
                    />
                  </div>
                )}
                {checkCompanyConfigField(companyID, 'isTrial') && !!isTrial && licenseType !== licenseTypes.subscription && (
                  <div className="section-row">
                    <div className="radio-btns">
                      <RadioBtn
                        name="trial-type-select"
                        inputId={trialTypes.days}
                        label={__('Trial duration')}
                        value={trialTypes.days}
                        checked={selectedTrialType === trialTypes.days}
                        handleChange={handleSelectedTrialTypeChange}
                      />
                      <div className="flex-row">
                        <NumberInput
                          handleChange={(val) => {
                            setDirty(true);
                            setTrialDays(val);
                            debouncedValidateRequiredNumber(val).then(err => setTrialDaysError(err));
                          }}
                          value={trialDays}
                          error={trialDaysError}
                          min="1"
                          max="1000"
                          disabled={selectedTrialType === trialTypes.endDate}
                        />
                        <Selector
                          options={[{ label: __('Days'), value: 'd' }]}
                          value="d"
                          handleChange={() => { }}
                          disabled={selectedTrialType === trialTypes.endDate}
                        />
                      </div>
                      <RadioBtn
                        name="trial-type-select"
                        inputId={trialTypes.endDate}
                        label={__('Trial until date:')}
                        value={trialTypes.endDate}
                        checked={selectedTrialType === trialTypes.endDate}
                        handleChange={handleSelectedTrialTypeChange}
                      />
                      <DateInput
                        id="trial-end-date"
                        handleChange={(val) => {
                          setDirty(true);
                          setTrialEndDate(val);
                          debouncedValidateDate(val).then(err => setTrialEndDateError(err));
                        }}
                        value={trialEndDate}
                        error={trialEndDateError}
                        disabled={selectedTrialType === trialTypes.days}
                      />
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
          <div className="sections-wrapper">
            <section>
              <div className="section-header first">
                {__('License options')}
              </div>
              <div className="license-options-settings section-settings">
                {checkCompanyConfigField(companyID, 'maxActivations') && (
                  <div className="section-row max-activations">
                    <Label text={__('Max activations')} inputId="max-activations" />
                    <NumberInput
                      id="max-activations"
                      value={maxActivations}
                      error={maxActivationsError}
                      min="1"
                      max="2147483647"
                      handleChange={handleMaxActivationsChange}
                      disabled={allowUnlimitedActivations}
                    />
                    <Checkbox
                      label={__('Allow unlimited activations')}
                      inputId="allow-unlimited-activations-checkbox"
                      checked={allowUnlimitedActivations}
                      handleChange={handleAllowUnlimitedActivations}
                    />
                  </div>
                )}
                <div className="section-row">
                  <Checkbox
                    label={__('Prevent device transfers')}
                    inputId="prevent-transfers-checkbox"
                    checked={preventMaxTransfers}
                    handleChange={(val) => {
                      setDirty(true);
                      setPreventMaxTransfers(val);
                      setMaxTransfers(-1);
                      if (val) {
                        setHasMaxTransfers(false);
                      }
                    }}
                    featureEnabled={isFeatureEnabled(platformFeatures.extra_max_transfers)}
                    notEnabledMessage={getDisabledMessage()}
                    featureAvailable={isFeatureAvailable(platformFeatures.extra_max_transfers)}
                    notAvailableMessage={notAvailableMaxTransfersMessage}
                  />
                </div>
                <div className="section-row">
                  <Checkbox
                    label={__('Has device transfer limit')}
                    inputId="max-transfers-checkbox"
                    checked={hasMaxTransfers}
                    handleChange={(val) => {
                      setDirty(true);
                      setHasMaxTransfers(val);
                      setMaxTransfers(get(license, 'max_transfers') || 0);
                      setMaxTransfersError('');
                      if (val) {
                        setPreventMaxTransfers(false);
                      }
                    }}
                    featureEnabled={isFeatureEnabled(platformFeatures.extra_max_transfers)}
                    notEnabledMessage={getDisabledMessage()}
                    featureAvailable={isFeatureAvailable(platformFeatures.extra_max_transfers)}
                    notAvailableMessage={notAvailableMaxTransfersMessage}
                  />
                  {!!hasMaxTransfers && (
                    <NumberInput
                      handleChange={(val) => {
                        setDirty(true);
                        setMaxTransfers(val);
                        validateValue(val, setMaxTransfersError);
                      }}
                      value={maxTransfers}
                      error={maxTransfersError}
                      min="1"
                      max="2147483647"
                      id="max-transfers-input"
                      placeholder={__('Device transfer limit')}
                    />
                  )}
                </div>
                {isUserBased && (
                  <div className="max-users">
                    <div className="section-row row-max-license-users">
                      <Label text={__('Max license users')} />
                      <NumberInput
                        handleChange={(val) => {
                          setDirty(true);
                          setMaxLicenseUsers(val);
                          debouncedValidateRequiredNumber(val, isFloatingCloud).then(err => setMaxLicenseUsersError(err));
                        }}
                        value={maxLicenseUsers}
                        error={maxLicenseUsersError}
                        min="0"
                        max="2147483647"
                        disabled={unlimitedMaxLicenseUsers}
                      />
                    </div>
                    <div className="section-row row-max-license-users-checkbox">
                      <Checkbox
                        label={__('Unlimited users')}
                        description={__('Only when is floating cloud')}
                        inputId="max-license-users-checkbox"
                        checked={unlimitedMaxLicenseUsers}
                        handleChange={(val) => {
                          setDirty(true);
                          setUnlimitedMaxLicenseUsers(val);
                          if (val) {
                            setMaxLicenseUsers(0);
                            setMaxLicenseUsersError('');
                          }
                        }}
                        disabled={!isFloatingCloud}
                      />
                    </div>
                  </div>
                )}
                <div className="section-row">
                  <Checkbox
                    label={__('Prevent virtual machine')}
                    inputId="product-prevent-vm"
                    checked={preventVm}
                    handleChange={(val) => {
                      setDirty(true);
                      setPreventVm(val);
                    }}
                    featureEnabled={isFeatureEnabled(platformFeatures.extra_prevent_vm)}
                    notEnabledMessage={getDisabledMessage()}
                    featureAvailable={isFeatureAvailable(platformFeatures.extra_prevent_vm)}
                    notAvailableMessage={notAvailablePreventVmMessage}
                  />
                </div>
              </div>
            </section>
            <section>
              <div className="section-header license-usage">
                {__('License usage')}
              </div>
              <div className="license-usage-settings section-settings">
                <div className="section-row">
                  <Label
                    inputId="start-date"
                    text={__('License start date')}
                    description={__('License cannot be activated before this date')}
                  />
                  <DateInput
                    id="start-date"
                    handleChange={(val) => {
                      setDirty(true);
                      setStartDate(val);
                      validateStartDateField(val, setStartDateError, true);
                    }}
                    value={startDate}
                    error={startDateError}
                  />
                </div>
                <div className="section-row">
                  <Checkbox
                    label={__('Offline floating license')}
                    inputId="floating-license"
                    checked={isFloating}
                    handleChange={(val) => {
                      setDirty(true);
                      setIsFloating(val);
                      setIsFloatingCloud(false);
                      setFloatingUsers(get(license, 'floating_users') || 1);
                      setFloatingUsersError('');
                    }}
                    featureEnabled={isFeatureEnabled(platformFeatures.model_floating_cloud)}
                    notEnabledMessage={getDisabledMessage()}
                    featureAvailable={isFeatureAvailable(platformFeatures.model_floating_cloud)}
                    notAvailableMessage={notAvailableFloatingMessage}
                  />
                </div>
                <div className="section-row">
                  <Checkbox
                    label={__('Is floating cloud')}
                    inputId="floating-cloud-license"
                    checked={isFloatingCloud}
                    handleChange={(val) => {
                      setDirty(true);
                      setIsFloatingCloud(val);
                      setIsFloating(false);
                      setFloatingUsers(get(license, 'floating_users') || 1);
                      setFloatingUsersError('');
                      if (!val) {
                        setUnlimitedMaxLicenseUsers(false);
                      }
                      if (val && maxLicenseUsers === 0) {
                        setMaxLicenseUsersError('');
                        setUnlimitedMaxLicenseUsers(true);
                      }
                    }}
                    featureEnabled={isFeatureEnabled(platformFeatures.model_floating_cloud)}
                    notEnabledMessage={getDisabledMessage()}
                    featureAvailable={isFeatureAvailable(platformFeatures.model_floating_cloud)}
                    notAvailableMessage={notAvailableFloatingMessage}
                  />
                </div>
                {((isFloating || isFloatingCloud)) && (
                  <div className="floating-form-fields">
                    <div className="section-row max-simult-users">
                      <Label text={__('Max simultaneous license users')} />
                      <NumberInput
                        handleChange={(val) => {
                          setDirty(true);
                          setFloatingUsers(val);
                          debouncedValidateFloatingUsers(val, maxActivations, allowUnlimitedActivations, isFloatingCloud).then(err => setFloatingUsersError(err));
                        }}
                        value={floatingUsers}
                        error={floatingUsersError}
                        min="1"
                        max="10000"
                      />
                    </div>
                    <div className="section-row max-simult-users">
                      <Label text={__('Floating timeout')} description={__('If the license issued is a floating license, the floating timeout is the time interval in minutes that the end users application will need to perform a license check in order to remain registered to the license.')} />
                      <NumberInput
                        handleChange={(val) => {
                          setDirty(true);
                          setFloatingTimeout(val);
                          debouncedValidateRequiredNumber(val).then(err => setFloatingTimeoutError(err));
                        }}
                        value={floatingTimeout}
                        error={floatingTimeoutError}
                        min="1"
                        max="2147483647"
                      />
                    </div>
                    <Checkbox
                      label={__('Can borrow license')}
                      inputId="can-borrow-license"
                      checked={canBorrow}
                      handleChange={handleCanBorrowChange}
                      featureEnabled={isFeatureEnabled(platformFeatures.extra_license_borrowing)}
                      notEnabledMessage={getDisabledMessage()}
                      featureAvailable={isFeatureAvailable(platformFeatures.extra_license_borrowing)}
                      notAvailableMessage={notAvailableFloatingMessage}
                    />
                    {canBorrow && (
                      <div className="section-row row-expanded">
                        <Label text={__('Max borrow time')} description={__('Hours')} />
                        <NumberInput
                          handleChange={handleMaxBorrowTimeChange}
                          value={maxBorrowTime}
                          error={maxBorrowTimeError}
                          min="1"
                          max="2147483647"
                        />
                      </div>
                    )}
                  </div>
                )}
                {checkCompanyConfigField(companyID, 'maintenancePeriod') && (
                  <div className="section-row">
                    <Checkbox
                      label={__('Enable maintenance period')}
                      inputId="maintenance-period"
                      checked={hasMaintenance}
                      handleChange={(val) => {
                        setDirty(true);
                        setHasMaintenance(val);
                        setSelectedMaintenanceDurationType(validityTypes.duration);
                        setHasMaintenanceValue(getNumbersFromString(get(license, 'maintenance_duration') || '1y'));
                        setHasMaintenancePeriod(getCharsFromString(get(license, 'maintenance_duration') || '1y'));
                        setHasMaintenanceError('');
                        setHasMaintenanceEndDate(get(license, 'maintenance_period') || '');
                        setHasMaintenanceEndDateError('');
                      }}
                      featureEnabled={isFeatureEnabled(platformFeatures.deployment_maintenance)}
                      notEnabledMessage={getDisabledMessage()}
                      featureAvailable={isFeatureAvailable(platformFeatures.deployment_maintenance)}
                      notAvailableMessage={notAvailableMaintenanceMessage}
                    />
                  </div>
                )}
                {hasMaintenance && (
                  <div className="section-row">
                    <div className="radio-btns">
                      <RadioBtn
                        name="maintenance-type-select"
                        inputId="maintenance-duration"
                        label={__('Maintenance duration')}
                        value={validityTypes.duration}
                        checked={selectedMaintenanceDurationType === validityTypes.duration}
                        handleChange={(val) => {
                          setDirty(true);
                          setSelectedMaintenanceDurationType(val);
                          setHasMaintenanceEndDate(get(license, 'maintenance_period') || '');
                          setHasMaintenanceEndDateError('');
                        }}
                      />
                      <div>
                        <div className="flex-row">
                          <NumberInput
                            handleChange={(val) => {
                              setDirty(true);
                              setHasMaintenanceValue(val);
                              debouncedValidateRequiredNumber(val).then(err => setHasMaintenanceError(err));
                            }}
                            value={hasMaintenanceValue}
                            min="0"
                            max="1000"
                            disabled={selectedMaintenanceDurationType === validityTypes.period}
                          />
                          <Selector
                            options={periodOptions}
                            value={hasMaintenancePeriod}
                            handleChange={(val) => {
                              setDirty(true);
                              setHasMaintenancePeriod(val);
                            }}
                            disabled={selectedMaintenanceDurationType === validityTypes.period}
                          />
                        </div>
                        <InputErrorMessage text={hasMaintenanceError} />
                      </div>
                      <RadioBtn
                        name="maintenance-type-select"
                        inputId="meaintenance-period"
                        label={__('Maintenance End Date')}
                        value={validityTypes.period}
                        checked={selectedMaintenanceDurationType === validityTypes.period}
                        handleChange={(val) => {
                          setDirty(true);
                          setSelectedMaintenanceDurationType(val);
                          setHasMaintenanceValue(getNumbersFromString(get(license, 'maintenance_duration') || '1y'));
                          setHasMaintenancePeriod(getCharsFromString(get(license, 'maintenance_duration') || '1y'));
                          setHasMaintenanceError('');
                        }}
                      />
                      <DateInput
                        id="maintenance-end-date"
                        handleChange={handleMaintenanceEndDateChange}
                        value={hasMaintenanceEndDate}
                        error={hasMaintenanceEndDateError}
                        disabled={selectedMaintenanceDurationType === validityTypes.duration}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="section-row">
                <Checkbox
                  label={__('Require hardware key')}
                  inputId="require-hw-key"
                  checked={isHwKeyAuth}
                  handleChange={(val) => {
                    setDirty(true);
                    setHwKeyAuth(val);
                  }}
                  featureEnabled={isFeatureEnabled(platformFeatures.extra_hardware_token)}
                  notEnabledMessage={getDisabledMessage()}
                  featureAvailable={isFeatureAvailable(platformFeatures.extra_hardware_token)}
                  notAvailableMessage={notAvailableHwKeyMessage}
                  disabled={isLicenseActivated}
                />
              </div>
              <div className="section-row">
                <Label text={__('Note')} inputId="license-note" />
                <TextArea
                  id="license-note"
                  value={licenseNote}
                  handleChange={(val) => {
                    setDirty(true);
                    setLicenseNote(val);
                  }}
                />
              </div>
              <div className="row">
                <Label inputId="metadata-input" text={__('Metadata JSON')} />
                <TextArea
                  handleChange={(val) => {
                    setDirty(true);
                    setMetadata(val);
                    debouncedValidateJSON(val)
                      .then(err => setMetadataError(err));
                  }}
                  id="metadata-input"
                  type="metadata"
                  value={metadata}
                  error={metadataError}
                  rows="4"
                  disabled={!isEnterprisePlan}
                />
              </div>
            </section>
          </div>
        </div>
        {
          isDirtyFormAlertDisplayed && (
            <DirtyFormAlert
              dirty={isDirty}
              closeAlert={() => setDirtyFormAlertDisplay(false)}
              closeCb={closeCb}
            />
          )
        }
      </form>
    </SlidePane>
  );
};

EditLicenseForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  license: PropTypes.object.isRequired,
  currentProduct: PropTypes.object.isRequired,
  refetchLicense: PropTypes.func.isRequired,
};

export default EditLicenseForm;
