import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import {
  Checkbox,
  DateInput,
  DirtyFormAlert,
  InputErrorMessage,
  Label,
  Modal,
  TextInput,
  Selector,
  TextArea,
  NumberInput,
} from 'shared/components';
import {
  sendErrorReport,
  parseMetadata,
} from 'shared/helpers';
import {
  validateRequiredNumber,
  debouncedValidateRequiredNumber,
  validateDate,
  debouncedValidateDate,
  validateJSON,
  debouncedValidateJSON,
} from 'shared/validation';
import './styles.scss';

const ProductFeaturesEditorForm = ({
  closeCb,
  feature,
  onConfirm,
}) => {
// todo_metadata replace this check with company feature
  const companyDetails = useSelector(state => get(state, 'company.details'));
  const isEnterprisePlan = get(companyDetails, 'plan_type') === 'enterprise';

  const isConsumptionType = get(feature, 'feature_type') === 'consumption';
  const isFloating = get(feature, 'is_floating');
  const isFloatingCloud = get(feature, 'is_floating_cloud');
  const isFloatingFeature = isFloating || isFloatingCloud;

  const consumptionPeriodOptions = [
    { label: __('Daily'), value: 'daily' },
    { label: __('Weekly'), value: 'weekly' },
    { label: __('Monthly'), value: 'monthly' },
    { label: __('Annually'), value: 'annually' },
  ];

  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormDisplayed, setDirtyFormDisplay] = useState(false);
  const [maxConsumption, setMaxConsumption] = useState(get(feature, 'max_consumption') || 0);
  const [maxConsumptionError, setMaxConsumptionError] = useState('');
  const [allowUnlimitedConsumptions, setAllowUnlimitedConsumptions] = useState(get(feature, 'allow_unlimited_consumptions') || false);
  const [expiryDate, setExpiryDate] = useState(get(feature, 'expiry_date') || '');
  const [expiryDateError, setExpiryDateError] = useState('');
  const [allowOverages, setAllowOverages] = useState(get(feature, 'allow_overages'));
  const [maxOverages, setMaxOverages] = useState(get(feature, 'max_overages') || 0);
  const [maxOveragesError, setMaxOveragesError] = useState('');
  const [resetConsumption, setResetConsumption] = useState(get(feature, 'reset_consumption'));
  const [resetConsumptionPeriod, setResetConsumptionPeriod] = useState(get(feature, 'consumption_period') || 'daily');
  // metadata
  const [metadata, setMetadata] = useState(parseMetadata(get(feature, 'metadata')));
  const [metadataError, setMetadataError] = useState('');
  // floating
  const [floatingUsers, setFloatingUsers] = useState(get(feature, 'floating_users') || 1);
  const [floatingUsersError, setFloatingUsersError] = useState('');
  const [floatingTimeout, setFloatingTimeout] = useState(get(feature, 'floating_timeout') || 120);
  const [floatingTimeoutError, setFloatingTimeoutError] = useState('');

  const handleClose = () => {
    if (!isDirty) { return closeCb(); }
    return setDirtyFormDisplay(true);
  };

  const validateValue = async (val, cb) => {
    setLoading(true);
    let errors;
    try {
      errors = await validateRequiredNumber(val);
      cb(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate feature form value', { value: val });
    }
    setLoading(false);
    if (errors) { return false; }
    return true;
  };

  const handleDateValidation = async () => {
    setLoading(true);
    let errors;
    try {
      errors = await validateDate(expiryDate, false);
      setExpiryDateError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate feature form value', { value: expiryDate });
    }
    setLoading(false);
    if (errors) { return false; }
    return true;
  };

  const validateMetadata = async (val) => {
    setLoading(true);
    let errors;
    try {
      errors = await validateJSON(metadata);
      setMetadataError(errors);
    } catch (err) {
      sendErrorReport(err, 'Cannot validate edit feature form value', { value: val });
    }
    setLoading(false);
    if (errors) { return false; }
    return true;
  };

  const handleAllowOveragesChange = (val) => {
    setDirty(true);
    setAllowOverages(val);
    setMaxOverages((get(feature, 'max_overages') || 0));
    setMaxOveragesError('');
  };

  const handleMaxOveragesChange = (val) => {
    setDirty(true);
    setMaxOverages(val);
    debouncedValidateRequiredNumber(val).then(err => setMaxOveragesError(err));
  };

  const handleResetConsumptionChange = (val) => {
    setDirty(true);
    setResetConsumption(val);
  };

  const handleResetConsumptionPeriodChange = (val) => {
    setDirty(true);
    setResetConsumptionPeriod(val);
  };

  const handleAllowUnlimitedConsumptions = (val) => {
    setDirty(true);
    setAllowUnlimitedConsumptions(val);
    setMaxConsumption(get(feature, 'max_consumption') || 1);
    setMaxConsumptionError('');
  };

  const isFormValid = async () => {
    const isFloatingUsersValid = isFloatingFeature ? await validateValue(floatingUsers, setFloatingUsersError) : true;
    const isFloatingTimeoutValid = isFloatingFeature ? await validateValue(floatingTimeout, setFloatingTimeoutError) : true;
    const isMaxConsumptionsValid = isConsumptionType ? await validateValue(maxConsumption, setMaxConsumptionError) : true;
    const isMaxOveragesValid = (isConsumptionType && allowOverages) ? await validateValue(maxOverages, setMaxOveragesError) : true;
    const isDateValid = await handleDateValidation();
    const isMetadataValid = await validateMetadata(metadata, setMetadataError);
    return isMaxConsumptionsValid && isMaxOveragesValid && isDateValid && isMetadataValid && isFloatingUsersValid && isFloatingTimeoutValid;
  };

  const getFloatingUsers = () => {
    if (!isFloating && !isFloatingCloud) {
      return undefined;
    }
    if (floatingUsers) {
      return Number(floatingUsers);
    }
    return undefined;
  };

  const getFloatingTimeout = () => {
    if (!isFloating && !isFloatingCloud) {
      return undefined;
    }
    if (floatingTimeout) {
      return Number(floatingTimeout);
    }
    return undefined;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const floatingUsersNumber = getFloatingUsers();
    const floatingTimeoutValue = getFloatingTimeout();

    const data = {
      ...feature,
      max_consumption: Number(maxConsumption),
      allow_unlimited_consumptions: allowUnlimitedConsumptions,
      allow_overages: allowOverages,
      max_overages: allowOverages ? Number(maxOverages) : undefined,
      reset_consumption: resetConsumption,
      consumption_period: resetConsumption ? resetConsumptionPeriod : undefined,
      expiry_date: expiryDate || null,
      metadata: metadata ? JSON.parse(metadata) : {},
      is_floating: isFloating,
      is_floating_cloud: isFloatingCloud,
      floating_users: floatingUsersNumber,
      floating_timeout: floatingTimeoutValue || 120,
    };

    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    setLoading(true);

    onConfirm(data);
    return true;
  };

  return (
    <Modal
      confirmCb={handleSubmit}
      closeCb={handleClose}
      disabled={isLoading}
      title={__('Edit product feature')}
      size="sm"
    >
      <div className="ProductFeaturesEditorForm">
        <Label text={__('Feature name')} inputId="name" />
        <TextInput
          value={get(feature, 'data.name') || get(feature, 'product_feature.name')}
          handleChange={() => { }}
          disabled
        />
        {isConsumptionType && (
          <>
            <div>
              <Label text={__('Max consumption')} inputId="consumption" />
              <NumberInput
                handleChange={(val) => {
                  setDirty(true);
                  setMaxConsumption(val);
                  debouncedValidateRequiredNumber(val).then(err => setMaxConsumptionError(err));
                }}
                value={maxConsumption}
                error={maxConsumptionError}
                min="1"
                max="2147483647"
                disabled={allowUnlimitedConsumptions}
              />
              <Checkbox
                label={__('Allow unlimited consumptions')}
                inputId="allow-unlimited-consumptions-checkbox"
                checked={allowUnlimitedConsumptions}
                handleChange={handleAllowUnlimitedConsumptions}
              />
            </div>
            <div className="form-row">
              <Checkbox
                label={__('Allow overages')}
                inputId="consumption-overages"
                checked={allowOverages}
                handleChange={handleAllowOveragesChange}
              />
            </div>
            {allowOverages && (
              <div className="form-row row-expanded expanded-consumption-row">
                <Label text={__('Max overages')} inputId="max-overages-input" />
                <NumberInput
                  handleChange={handleMaxOveragesChange}
                  value={maxOverages}
                  showErrorMsg={false}
                  error={maxOveragesError}
                  min="0"
                  max="2147483647"
                  id="max-overages-input"
                />
                <InputErrorMessage text={maxOveragesError} />
              </div>
            )}
            <div className="form-row">
              <Checkbox
                label={__('Reset consumption')}
                inputId="consumption-reset"
                checked={resetConsumption}
                handleChange={handleResetConsumptionChange}
              />
            </div>
            {resetConsumption && (
              <div className="form-row row-expanded">
                <Label text={__('Consumption period')} />
                <Selector
                  options={consumptionPeriodOptions}
                  value={resetConsumptionPeriod}
                  handleChange={handleResetConsumptionPeriodChange}
                />
              </div>
            )}
          </>
        )}
        {isEnterprisePlan && (
          <div>
            <Label text={__('Expiry date')} inputId="expiry_date" />
            <DateInput
              handleChange={(val) => {
                setDirty(true);
                setExpiryDate(val);
                debouncedValidateDate(val, false).then(err => setExpiryDateError(err));
              }}
              value={expiryDate}
              error={expiryDateError}
            />
          </div>
        )}
        <div className="row">
          <Label inputId="metadata-input" text={__('Metadata JSON')} />
          <TextArea
            handleChange={(val) => {
              setDirty(true);
              setMetadata(val);
              debouncedValidateJSON(val)
                .then(err => setMetadataError(err));
            }}
            id="metadata-input"
            type="metadata"
            value={metadata}
            error={metadataError}
            rows="4"
            // todo_metadata replace this check with company feature
            disabled={!isEnterprisePlan}
          />
        </div>
        {isFloatingFeature && (
          <>
            <div className="section-row max-simult-users">
              <Label text={__('Max simultaneous license users')} />
              <NumberInput
                handleChange={(val) => {
                  setDirty(true);
                  setFloatingUsers(val);
                  debouncedValidateRequiredNumber(val).then(err => setFloatingUsersError(err));
                }}
                value={floatingUsers}
                error={floatingUsersError}
                min="1"
                max="10000"
              />
            </div>
            <div className="section-row max-simult-users">
              <Label text={__('Floating timeout')} description={__('If the feature issued is a floating feature, the floating timeout is the time interval in minutes that the end users application will need to perform a license check in order to remain registered to the feature.')} />
              <NumberInput
                handleChange={(val) => {
                  setDirty(true);
                  setFloatingTimeout(val);
                  debouncedValidateRequiredNumber(val).then(err => setFloatingTimeoutError(err));
                }}
                value={floatingTimeout}
                error={floatingTimeoutError}
                min="1"
                max="2147483647"
              />
            </div>
          </>
        )}
      </div>
      {isDirtyFormDisplayed && (
        <DirtyFormAlert
          dirty={isDirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

ProductFeaturesEditorForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  feature: PropTypes.object.isRequired,
};

export default ProductFeaturesEditorForm;
