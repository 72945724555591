import { WebStorageStateStore } from 'oidc-client-ts';

const getAppConfigVar = (varName, fallback) => {
  const env = process.env.REACT_APP_ENV;
  const { appConfig } = window;

  if (!appConfig && env === 'localhost') {
    return fallback;
  }

  const variable = appConfig ? appConfig[varName] : null;

  if (!variable && env === 'localhost') {
    return fallback;
  }

  return variable || '';
};

export const oidcConfig = {
  authority: getAppConfigVar('keycloakAuthority', 'http://localhost:8081/realms/platform'),
  client_id: getAppConfigVar('keycloakClientId', 'platform'),
  redirect_uri: getAppConfigVar('keycloakRedirectUri', 'http://localhost:3000'),
  post_logout_redirect_uri: getAppConfigVar('keycloakLogoutRedirectUri', 'http://localhost:3000'),
  onSigninCallback: (user) => {
    if (user) {
      window.history.replaceState(
        {},
        document.title,
        window.location.pathname,
      );
    }
  },
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  stateStore: new WebStorageStateStore({ store: window.localStorage }),
};
