import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  DateInput,
  Label,
  LicenseTypeSelector,
  InputErrorMessage,
  NumberInput,
  Notice,
  RadioBtn,
  Selector,
} from 'shared/components';
import {
  licenseTypes,
  platformFeatures,
  overageTypes,
  trialTypes,
} from 'shared/constants';
import {
  isFeatureEnabled,
  getDisabledMessage,
} from 'shared/helpers';
import { checkCompanyConfigField } from 'shared/companyConfig';

const LicenseTypeSection = ({
  companyID,
  handleLicenseTypeChange,
  licenseType,
  licenseTypeError,
  periodOptions,
  // subscription type
  handleSubscriptionPeriodValueChange,
  subscriptionPeriodValue,
  subscriptionPeriodError,
  subscriptionPeriodLabel,
  handleSubscriptionPeriodLabelChange,
  // handleSubscriptionEndDateChange,
  // subscriptionEndDate,
  // subscriptionEndDateError,
  allowGracePeriod,
  handleAllowGracePeriod,
  gracePeriod,
  gracePeriodError,
  handleGracePeriodChange,
  // time-limited type
  selectedValidityType,
  handleValidityTypeChange,
  handleTimeLimitedPeriodValueChange,
  timeLimitedPeriodValue,
  timeLimitedPeriodLabel,
  handleTimeLimitedPeriodLabelChange,
  timeLimitedPeriodError,
  handleTimeLimitedEndDateChange,
  timeLimitedEndDate,
  timeLimitedEndDateError,
  // consumption type
  handleMaxConsumptionsChange,
  maxConsumptions,
  maxConsumptionsError,
  allowUnlimitedConsumptions,
  handleAllowUnlimitedConsumptions,
  allowOverages,
  handleAllowOveragesChange,
  selectedOverageType,
  handleSelectedOverageTypeChange,
  maxOverages,
  maxOveragesError,
  handleMaxOveragesChange,
  resetConsumption,
  handleResetConsumptionChange,
  consumptionPeriodOptions,
  resetConsumptionPeriod,
  handleResetConsumptionPeriodChange,
  // trial
  isTrial,
  handleTrialChange,
  selectedTrialType,
  handleTrialTypeChange,
  handleTrialDaysChange,
  trialDays,
  trialDaysError,
  handleTrialEndDateChange,
  trialEndDate,
  trialEndDateError,
}) => (
  <section className="LicenseTypeSection">
    <div className="section-header">{__('License type')}</div>
    <div className="section-row">
      <Label text={__('License Type')} inputId="license-type-selector" />
      <LicenseTypeSelector
        handleLicenseChange={handleLicenseTypeChange}
        selectedLicense={licenseType}
      />
      <InputErrorMessage text={licenseTypeError} />
    </div>
    {licenseType === licenseTypes.subscription && (
      <div className="section-row row-expanded subscription-expanded">
        <div className="section-row">
          <Label text={__('Default license duration')} inputId="subscription-period-value" />
          <div className="flex-row">
            <NumberInput
              handleChange={handleSubscriptionPeriodValueChange}
              value={subscriptionPeriodValue}
              error={subscriptionPeriodError}
              min="0"
              max="1000"
              disabled={selectedValidityType === 'valid_until'}
              showErrorMsg={false}
              id="subscription-period-value"
            />
            <Selector
              options={periodOptions}
              value={subscriptionPeriodLabel}
              handleChange={handleSubscriptionPeriodLabelChange}
              disabled={selectedValidityType === 'valid_until'}
            />
          </div>
          <InputErrorMessage text={subscriptionPeriodError} />
        </div>
        {false && (
          <>
            <div className="radio-btns">
              {/* <RadioBtn
                name="validity-type-select"
                inputId="valid_from"
                label={__('Subscription period')}
                description={__('Select the validity duration, which begins the moment the license has been activated')}
                value="valid_from"
                checked={selectedValidityType === 'valid_from'}
                handleChange={handleValidityTypeChange}
              /> */}
              {/* <Label text={__('Default license duration')} inputId="subscription-period-value" />
              <div className="flex-row">
                <NumberInput
                  handleChange={handleSubscriptionPeriodValueChange}
                  value={subscriptionPeriodValue}
                  error={subscriptionPeriodError}
                  min="0"
                  max="1000"
                  disabled={selectedValidityType === 'valid_until'}
                  showErrorMsg={false}
                  id="subscription-period-value"
                />
                <Selector
                  options={periodOptions}
                  value={subscriptionPeriodLabel}
                  handleChange={handleSubscriptionPeriodLabelChange}
                  disabled={selectedValidityType === 'valid_until'}
                />
              </div>
              <InputErrorMessage text={subscriptionPeriodError} /> */}
              {/* <RadioBtn
                name="validity-type-select"
                inputId="valid_until"
                label={__('Set end date')}
                description={__('Set the date in the future at which the license is set to expire')}
                value="valid_until"
                checked={selectedValidityType === 'valid_until'}
                handleChange={handleValidityTypeChange}
              /> */}
              {/* <DateInput
                id="subscription-end-date-value"
                handleChange={handleSubscriptionEndDateChange}
                value={subscriptionEndDate}
                error={subscriptionEndDateError}
                disabled={selectedValidityType === 'valid_from'}
              /> */}
            </div>
          </>
        )}
        <div className="section-row">
          <div className="form-row">
            <Checkbox
              label={__('Allow grace period')}
              inputId="allow-grace-period"
              checked={allowGracePeriod}
              handleChange={handleAllowGracePeriod}
            />
          </div>
          {allowGracePeriod && (
            <div className="form-row">
              <Label text={__('Grace period')} inputId="grace-period" description={__('Hours')} />
              <NumberInput
                handleChange={handleGracePeriodChange}
                value={gracePeriod}
                error={gracePeriodError}
                min="1"
                max="2147483647"
              />
            </div>
          )}
        </div>
        <Notice theme="warning" size="sm">
          <div className="actionable-notice">
            <div className="text">
              {__('LicenseSpring requires an integration to an external source of truth (like a recurring billing system). LicenseSpring updates the status of the license according to the status of the subscription, which is handled by a 3rd party. Without this integration, subscription licenses remain valid until otherwise specified')}
            </div>
          </div>
        </Notice>
      </div>
    )}
    {licenseType === licenseTypes.time_limited && (
      <div className="section-row row-expanded time-limited-row">
        <div className="radio-btns">
          <RadioBtn
            name="validity-type-select"
            inputId="valid_from"
            label={__('Valid from activation:')}
            description={__('Select the validity duration, which begins the moment the license has been activated')}
            value="valid_from"
            checked={selectedValidityType === 'valid_from'}
            handleChange={handleValidityTypeChange}
          />
          <div className="flex-row">
            <NumberInput
              handleChange={handleTimeLimitedPeriodValueChange}
              value={timeLimitedPeriodValue}
              error={timeLimitedPeriodError}
              min="0"
              max="1000"
              disabled={selectedValidityType === 'valid_until'}
              showErrorMsg={false}
            />
            <Selector
              options={periodOptions}
              value={timeLimitedPeriodLabel}
              handleChange={handleTimeLimitedPeriodLabelChange}
              disabled={selectedValidityType === 'valid_until'}
            />
          </div>
          <InputErrorMessage text={timeLimitedPeriodError} />
          <RadioBtn
            name="validity-type-select"
            inputId="valid_until"
            label={__('Set end date')}
            description={__('Set the date in the future at which the license is set to expire')}
            value="valid_until"
            checked={selectedValidityType === 'valid_until'}
            handleChange={handleValidityTypeChange}
          />
          <DateInput
            id="valid-until"
            handleChange={handleTimeLimitedEndDateChange}
            value={timeLimitedEndDate}
            error={timeLimitedEndDateError}
            disabled={selectedValidityType === 'valid_from'}
          />
        </div>
      </div>
    )}
    {licenseType === licenseTypes.consumption && (
      <div className="consumption-type">
        <div className="section-row initial-checkbox">
          <Label text={__('Max consumptions')} inputId="max-consumption-input" />
          <NumberInput
            handleChange={handleMaxConsumptionsChange}
            value={maxConsumptions}
            error={maxConsumptionsError}
            min="1"
            max="2147483647"
            id="max-consumption-input"
            disabled={allowUnlimitedConsumptions}
          />
          <Checkbox
            label={__('Allow unlimited consumptions')}
            inputId="allow-unlimited-consumptions-checkbox"
            checked={allowUnlimitedConsumptions}
            handleChange={handleAllowUnlimitedConsumptions}
          />
        </div>
        <div className="section-row">
          <Checkbox
            label={__('Allow overages')}
            inputId="consumption-overages"
            checked={allowOverages}
            handleChange={handleAllowOveragesChange}
            featureEnabled={isFeatureEnabled(platformFeatures.model_consumption)}
            notEnabledMessage={getDisabledMessage()}
          />
        </div>
        {allowOverages && (
          <div className="section-row row-expanded expanded-consumption-row">
            <Label text={__('Max overages')} inputId="max-overages-input" />
            <div className="radio-btns radio-btns-overages">
              <RadioBtn
                name="overages-select"
                inputId="overages-unlimited"
                label={__('Unlimited overages')}
                value="overages-unlimited"
                checked={selectedOverageType === overageTypes.unlimited}
                handleChange={handleSelectedOverageTypeChange}
              />
              <RadioBtn
                name="overages-select"
                inputId="overages-custom"
                label={__('Max overages')}
                value="overages-custom"
                checked={selectedOverageType === overageTypes.custom}
                handleChange={handleSelectedOverageTypeChange}
              />
              <NumberInput
                handleChange={handleMaxOveragesChange}
                value={maxOverages}
                showErrorMsg={false}
                error={maxOveragesError}
                min="0"
                max="2147483647"
                disabled={selectedOverageType === overageTypes.unlimited}
                id="max-overages-input"
              />
              <InputErrorMessage text={maxOveragesError} />
            </div>
          </div>
        )}
        <div className="section-row">
          <Checkbox
            label={__('Reset consumption')}
            inputId="consumption-reset"
            checked={resetConsumption}
            handleChange={handleResetConsumptionChange}
            featureEnabled={isFeatureEnabled(platformFeatures.model_consumption)}
            notEnabledMessage={getDisabledMessage()}
          />
        </div>
        {resetConsumption && (
          <div className="section-row row-expanded">
            <Label text={__('Consumption period')} />
            <Selector
              options={consumptionPeriodOptions}
              value={resetConsumptionPeriod}
              handleChange={handleResetConsumptionPeriodChange}
            />
          </div>
        )}
      </div>
    )}
    {checkCompanyConfigField(companyID, 'isTrial') && licenseType !== licenseTypes.subscription && (
      <div className="section-row">
        <Checkbox
          label={__('Is trial')}
          inputId="license-trial"
          checked={isTrial}
          handleChange={handleTrialChange}
        />
      </div>
    )}
    {checkCompanyConfigField(companyID, 'isTrial') && isTrial && licenseType !== licenseTypes.subscription && (
      <div className="section-row row-expanded">
        <div className="radio-btns">
          <RadioBtn
            name="trial-type-select"
            inputId={trialTypes.days}
            label={__('Trial duration')}
            value={trialTypes.days}
            checked={selectedTrialType === trialTypes.days}
            handleChange={handleTrialTypeChange}
          />
          <div className="flex-row">
            <NumberInput
              handleChange={handleTrialDaysChange}
              value={trialDays}
              error={trialDaysError}
              min="1"
              max="2147483647"
              disabled={selectedTrialType === trialTypes.endDate}
            />
            <Selector
              options={[{ label: __('Days'), value: 'd' }]}
              value="d"
              handleChange={() => { }}
              disabled={selectedTrialType === trialTypes.endDate}
            />
          </div>
          <RadioBtn
            name="trial-type-select"
            inputId={trialTypes.endDate}
            label={__('Trial until date:')}
            value={trialTypes.endDate}
            checked={selectedTrialType === trialTypes.endDate}
            handleChange={handleTrialTypeChange}
          />
          <DateInput
            id="trial-end-date"
            handleChange={handleTrialEndDateChange}
            value={trialEndDate}
            error={trialEndDateError}
            disabled={selectedTrialType === trialTypes.days}
          />
        </div>
      </div>
    )}
  </section>
);

LicenseTypeSection.propTypes = {
  companyID: PropTypes.number.isRequired,
  handleLicenseTypeChange: PropTypes.func.isRequired,
  licenseType: PropTypes.string,
  licenseTypeError: PropTypes.string,
  periodOptions: PropTypes.array.isRequired,
  handleSubscriptionPeriodValueChange: PropTypes.func.isRequired,
  subscriptionPeriodValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subscriptionPeriodError: PropTypes.string,
  subscriptionPeriodLabel: PropTypes.string,
  handleSubscriptionPeriodLabelChange: PropTypes.func.isRequired,
  // handleSubscriptionEndDateChange: PropTypes.func.isRequired,
  // subscriptionEndDate: PropTypes.string,
  // subscriptionEndDateError: PropTypes.string,
  allowGracePeriod: PropTypes.bool,
  handleAllowGracePeriod: PropTypes.func.isRequired,
  gracePeriod: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  gracePeriodError: PropTypes.string,
  handleGracePeriodChange: PropTypes.func.isRequired,
  selectedValidityType: PropTypes.string,
  handleValidityTypeChange: PropTypes.func.isRequired,
  handleTimeLimitedPeriodValueChange: PropTypes.func.isRequired,
  timeLimitedPeriodValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  timeLimitedPeriodLabel: PropTypes.string,
  handleTimeLimitedPeriodLabelChange: PropTypes.func.isRequired,
  timeLimitedPeriodError: PropTypes.string,
  handleTimeLimitedEndDateChange: PropTypes.func.isRequired,
  timeLimitedEndDate: PropTypes.string,
  timeLimitedEndDateError: PropTypes.string,
  handleMaxConsumptionsChange: PropTypes.func.isRequired,
  maxConsumptions: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxConsumptionsError: PropTypes.string,
  allowUnlimitedConsumptions: PropTypes.bool,
  handleAllowUnlimitedConsumptions: PropTypes.func.isRequired,
  allowOverages: PropTypes.bool,
  handleAllowOveragesChange: PropTypes.func.isRequired,
  selectedOverageType: PropTypes.string,
  handleSelectedOverageTypeChange: PropTypes.func.isRequired,
  maxOverages: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxOveragesError: PropTypes.string,
  handleMaxOveragesChange: PropTypes.func.isRequired,
  resetConsumption: PropTypes.bool,
  handleResetConsumptionChange: PropTypes.func.isRequired,
  consumptionPeriodOptions: PropTypes.array.isRequired,
  resetConsumptionPeriod: PropTypes.string,
  handleResetConsumptionPeriodChange: PropTypes.func.isRequired,
  isTrial: PropTypes.bool,
  handleTrialChange: PropTypes.func.isRequired,
  selectedTrialType: PropTypes.string,
  handleTrialTypeChange: PropTypes.func.isRequired,
  handleTrialDaysChange: PropTypes.func.isRequired,
  trialDays: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  trialDaysError: PropTypes.string,
  handleTrialEndDateChange: PropTypes.func.isRequired,
  trialEndDate: PropTypes.string,
  trialEndDateError: PropTypes.string,
};

LicenseTypeSection.defaultProps = {
  licenseType: licenseTypes.perpetual,
  licenseTypeError: '',
  subscriptionPeriodValue: '',
  subscriptionPeriodError: '',
  subscriptionPeriodLabel: '',
  subscriptionEndDate: '',
  subscriptionEndDateError: '',
  allowGracePeriod: false,
  gracePeriod: 1,
  gracePeriodError: '',
  selectedValidityType: 'valid_from',
  timeLimitedPeriodValue: '',
  timeLimitedPeriodLabel: '',
  timeLimitedPeriodError: '',
  timeLimitedEndDate: '',
  timeLimitedEndDateError: '',
  maxConsumptions: '',
  maxConsumptionsError: '',
  allowUnlimitedConsumptions: false,
  allowOverages: false,
  selectedOverageType: overageTypes.custom,
  maxOverages: '',
  maxOveragesError: '',
  resetConsumption: false,
  resetConsumptionPeriod: '',
  isTrial: false,
  selectedTrialType: trialTypes.days,
  trialDays: 0,
  trialDaysError: '',
  trialEndDate: '',
  trialEndDateError: '',
};

export default LicenseTypeSection;
